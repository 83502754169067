/**
 * Load GA4 event listeners
 * */


// view order received page event
jQuery(document).on("wpmOrderReceivedPage", function () {

	try {
		if (!wpmDataLayer?.pixels?.google?.analytics?.ga4?.measurement_id) return

		// ga4_server_and_browser_tracking experiment
		// ga4e: if (wpmDataLayer?.pixels?.google?.analytics?.ga4?.mp_active) {
		// 	if (wpmDataLayer?.experiments?.ga4_server_and_browser_tracking) {
		// 		break ga4e
		// 	}
		// 	return
		// }

		// Deactivated for the ga4_server_and_browser_tracking experiment
		if (wpmDataLayer?.pixels?.google?.analytics?.ga4?.mp_active) return

		if (!wpm.googleConfigConditionsMet("analytics")) return

		wpm.gtagLoaded().then(function () {
			gtag("event", "purchase", {
				send_to       : [wpmDataLayer.pixels.google.analytics.ga4.measurement_id],
				transaction_id: wpmDataLayer.order.number,
				affiliation   : wpmDataLayer.order.affiliation,
				currency      : wpmDataLayer.order.currency,
				value         : wpmDataLayer.order.value_regular,
				discount      : wpmDataLayer.order.discount,
				tax           : wpmDataLayer.order.tax,
				shipping      : wpmDataLayer.order.shipping,
				coupon        : wpmDataLayer.order.coupon,
				items         : wpm.getGA4OrderItems(),
			})
		})

		// wpm.gtagLoaded().then(function () {
		// 	gtag("get", wpmDataLayer.pixels.google.analytics.ga4.measurement_id, "client_id", (client_id) => {
		// 		console.log("transaction_id: " + wpmDataLayer.order.number + " client_id: " + client_id)
		// 	})
		// })

	} catch (e) {
		console.error(e)
	}
})
