/**
 * Load all WPM functions
 *
 * Ignore event listeners. They need to be loaded after
 * we made sure that jQuery has been loaded.
 */

require("./functions")
require("./cookie_consent")
require("./ip_check")

// #if process.env.TIER === 'premium'
// if (wpm.canLoadPremiumFeatures()) {
// 	require("./functions_premium")
// }
// #endif
